.product-page {
    display: flex;
    flex-direction: row;
    margin: 20px;
  }
  
  .product-embed {
    flex: 1;
    margin-right: 20px;
  }
  
  .product-details {
    flex: 2;
    display: flex;
    flex-direction: column;
  }
  
  .product-details h1 {
    font-size: 3em;
    margin-bottom: 10px;
  }
  
  .product-details p {
    font-size: 1.2em;
    line-height: 2;
    margin-bottom: 10px;
  }
  
  .product-details ul {
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 10px;
  }
  
  .product-details ul li {
    margin-bottom: 5px;
  }
  
  .product-details strong {
    font-size: 1.2em;
  }
  
  .product-details button {
    padding: 10px 20px;
    font-size: 1em;
    background-color: #200b3f;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 25px; /* Rounded edges */
    white-space: nowrap; /* Prevent text wrapping */
    width: 200px;
  }
  
  
  .product-details button:hover {
    background-color: #0056b3;
  }
  